@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply m-0 dark:bg-black
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

.desights-logo {
  @apply h-fit w-32 sm:w-36;
}
.desights-logo #primary-path-outer,
.desights-logo #primary-path-inner {
  fill: theme("colors.primary.500") !important;
}

.hero-image > g {
  @apply invert dark:filter-none;
}

.get_assets span {
  @apply !py-0
}

.paddlessTab > button,
.paddlessTab > div > div {
  padding: 0 !important;
}

.paddlessTab {
  gap: 0 !important;
  margin: 0 !important;
}

.profile_section button {
  padding: 0px !important;
}

.profile_section button:hover {
  background-color: #eef2ff;
}

.activehover li {
  width: 100%;
}

.profile_section button {
  padding: 0px !important;
}

.truncate-4 {
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff00;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  @apply dark:bg-primary-600 bg-primary-300;
}

::-webkit-scrollbar-thumb:hover {
  background: theme("colors.primary.700");
}

::-moz-selection {
  @apply bg-primary-500 text-white;
}

::selection {
  @apply bg-primary-500 text-white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.text-editor-container .text-editor-wrapper {
  border: 1px solid #d6d6d6;
  margin-top: 10px;
  overflow-y: hidden;
}

.text-editor-container .rdw-link-modal {
  height: auto !important;
}

:root {
  --dynamicBG-color: "white";
  --dynamicBG-fontColor: "white";
  --linkColor: "#0000EE";
}

.text-editor-container .rdw-link-decorator-wrapper {
  color: var(--linkColor) !important;
}

.text-editor-container .text-editor-textArea .DraftEditor-editorContainer {
  /* height: 9rem !important; */
}

.text-editor-container .showScroll .text-editor-textArea .DraftEditor-root {
  height: 0 !important;
}

.text-editor-container .rdw-link-decorator-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.individual_edit_input input {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  border-bottom: solid 1px !important;
  padding: 0;
}

.individual_edit_input input:focus {
  outline: none;
}

.text-editor-container .text-editor-toolBar {
  background-color: #f5f5f5 !important;
}

.text-editor-container .text-editor-wrapper {
  border-radius: 12px !important;
}

.text-editor-container .hideScroll {
  border: 0px !important;
  height: 100%;
}

.text-editor-container .rdw-editor-toolbar {
  margin-bottom: 0px !important;
}

.text-editor-container h1 {
  word-break: break-word;
}

.rdw-editor-main pre {
  color: black;
}

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }

  h2 {
    @apply text-3xl;
    @apply font-bold;
  }

  h3 {
    @apply text-2xl;
    @apply font-bold;
  }

  h4 {
    @apply text-xl;
    @apply font-semibold;
  }

  h5 {
    @apply text-sm;
    @apply font-semibold;
  }

  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
}

.text-editor-container .text-editor-textArea {
  padding: 0px 15px;
}

.input_date_container
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.dark-theme .rdw-option-wrapper {
  background-color: rgb(165, 161, 161) !important;
  border: 0 !important;
}

.dark-theme .rdw-dropdown-wrapper {
  background-color: rgb(165, 161, 161) !important;
  color: black;
  border: 0 !important;
}

.text-editor-container .public-DraftStyleDefault-block {
  margin: 0 !important;
}

.dark-mode {
  color-scheme: dark;
}

.text_editor a {
  /* color: blue !important; */
}

.line_height {
  line-height: 0.4 !important;
}

.text-editor-container {
  /* height: 100%; */
}

.number_field input::-webkit-outer-spin-button,
.number_field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number_field input[type="number"] {
  -moz-appearance: textfield;
}

.dark-theme .rdw-image-modal,
.dark-theme .rdw-image-modal-url-input,
.dark-theme .rdw-link-modal-input,
.dark-theme .rdw-image-modal-size-input,
.dark-theme .rdw-link-modal,
.dark-theme .rdw-dropdown-optionwrapper,
.dark-theme .rdw-dropdownoption-active {
  background-color: #374151;
  color: #ffffff;
  box-shadow: none;
}

.dark-theme .rdw-dropdown-optionwrapper:hover {
  background-color: #374151;
}

.dark-theme .rdw-dropdownoption-highlighted {
  color: black !important;
}

.rdw-image-modal-btn:first-child,
.rdw-link-modal-btn:first-child,
.rdw-image-modal-btn[disabled]:first-child,
.rdw-link-modal-btn[disabled]:first-child,
.dark-theme .rdw-image-modal-btn[disabled]:first-child,
.dark-theme .rdw-link-modal-btn[disabled]:first-child {
  background-color: #1d4ed8 !important;
  color: white !important;
  box-shadow: none;
}

.dark-theme .rdw-image-modal-btn,
.dark-theme .rdw-link-modal-btn {
  background-color: #1d4ed8;
  color: #ffffff;
  border-radius: 5px;
}

.dark-theme .rdw-image-modal-btn:last-child,
.dark-theme .rdw-link-modal-btn:last-child {
  background-color: #ffffff;
  color: black;
  border: 2px solid #1c64f2;
  border-radius: 5px;
}

.dark-theme .rdw-image-modal-btn:last-child:hover,
.dark-theme .rdw-link-modal-btn:last-child:hover {
  box-shadow: none;
}

button:focus-visible {
  outline: none;
}

.common-avatar > div > img {
  object-fit: cover;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
  caret-color: white;
  -webkit-box-shadow: 0 0 0 30px rgb(55 65 81 / var(--tw-bg-opacity)) inset !important;
  -webkit-text-fill-color: rgb(255 255 255 / var(--tw-text-opacity));
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.statsbar-gradient {
  background: radial-gradient(
      60% 300% at 0% 240%,
      theme("colors.primary.400") 0% 90%,
      #0000 81% 100%
    ),
    linear-gradient(theme("colors.primary.300"), theme("colors.primary.500"));
}
.dark .statsbar-gradient {
  background: radial-gradient(
      60% 300% at 0% 240%,
      theme("colors.gray.900") 0% 90%,
      #0000 81% 100%
    ),
    linear-gradient(theme("colors.gray.900"), theme("colors.gray.800"));
}

@media only screen and (min-width: 1023px) {
  .statsbar-gradient {
    background: radial-gradient(
        30% 300% at 5% 235%,
        theme("colors.primary.400") 0% 90%,
        #0000 81% 100%
      ),
      linear-gradient(theme("colors.primary.300"), theme("colors.primary.500"));
  }
  .dark .statsbar-gradient {
    background: radial-gradient(
        30% 300% at 5% 235%,
        theme("colors.gray.900") 0% 90%,
        #0000 81% 100%
      ),
      linear-gradient(theme("colors.gray.900"), theme("colors.gray.800"));
  }
}

@media only screen and (max-width: 640px) {
  .statsbar-gradient {
    background: radial-gradient(
        75% 275% at 0% 248%,
        theme("colors.primary.400") 0% 90%,
        #0000 81% 100%
      ),
      linear-gradient(theme("colors.primary.300"), theme("colors.primary.500"));
  }
  .dark .statsbar-gradient {
    background: radial-gradient(
        75% 275% at 0% 248%,
        theme("colors.gray.900") 0% 90%,
        #0000 81% 100%
      ),
      linear-gradient(theme("colors.gray.900"), theme("colors.gray.800"));
  }
}

.reactEasyCrop_CropArea {
  width: 300px !important;
  height: 300px !important;
}

.common-modal > div {
  @apply flex;
}

.common-modal > div > div {
  @apply self-center w-full dark:bg-gray-800;
}

.color stop:nth-child(odd) {
  stop-color: theme("colors.primary.600");
}

.color stop:nth-child(even) {
  stop-color: theme("colors.primary.300");
}

.admin-table {
  @apply w-[75vw] lg:w-[65vw];
}

.asset-table {
  @apply w-[75vw] lg:w-[50vw];
}

.admin-table > div,
.asset-table > div {
  @apply rounded-lg;
}

.token-unit {
  @apply rounded-lg tracking-wide border border-gray-300 focus-within:ring-1 focus-within:border-primary-500 focus-within:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 ;
}

.token-unit input {
  @apply text-gray-500 bg-transparent dark:text-white dark:placeholder-gray-400 border-none focus:border-transparent focus-within:border-transparent focus:ring-0 p-0 text-xl max-w-[15ch] min-w-[4ch];
}

/* FOR LOADING DOTS COMPONENT */
.loading-dots-container {
  display: flex;
  align-items: flex-end; /* Align items to the bottom of the container */
}

.loading-text {
  margin-right: 10px; /* Add some space to the right of the text */
}

.loading-dots {
  display: flex;
}

.dot {
  width: 4px;
  height: 4px;
  margin: 0 2px;
  border-radius: 50%;
  animation: appear 1.5s infinite;
  opacity: 0;
}

.dot1 {
  animation-delay: 0.2s;
}

.dot2 {
  animation-delay: 0.4s;
}

.dot3 {
  animation-delay: 0.6s;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.word-break {
  word-break: break-word;
}
